import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DIALOG_TRANSITION_DURATION } from '../../../../../shared/constants';
import TextField from '@mui/material/TextField';
import { ConfirmationDialog } from '../../../components/Common/ConfirmationDialog';

type RejectedOfferDialogProps<T extends Record<string, string>> = {
  loading?: boolean;
  onClose: () => void;
  onConfirm: (rejectionReason: keyof T, comment: string) => void;
  open: boolean;
  reasons: T;
  title: string;
};

export const RejectedOfferDialog = <
  T extends Record<string, string>,
>({
  loading = false,
  onClose = () => null,
  onConfirm = () => null,
  open,
  reasons,
  title,
}: RejectedOfferDialogProps<T>) => {
  const INITIAL_SELECTION = Object.keys(reasons)[0];

  const [rejectionReason, setRejectionReason] =
    useState<keyof T>(INITIAL_SELECTION);
  const [comment, setComment] = useState('');

  // reset state when dialog is closed (after transition is complete)
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setComment('');
        setRejectionReason(INITIAL_SELECTION);
      }, DIALOG_TRANSITION_DURATION);
    }
  }, [open, INITIAL_SELECTION]);
  return (
    <ConfirmationDialog
      open={open}
      loading={loading}
      onCancel={onClose}
      onConfirm={() => onConfirm(rejectionReason, comment)}
      title={title}
      confirmButtonLabel={'Vahvista'}
    >
      <RadioGroup
        name={'reason'}
        value={rejectionReason}
        onChange={(e) => setRejectionReason(e.target.value)}
      >
        {Object.entries(reasons).map(([value, label]) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio size={'small'} />}
            label={label}
          />
        ))}
      </RadioGroup>
      <TextField
        fullWidth
        multiline
        onChange={(event) => setComment(event.target.value)}
        label={'Kommentti'}
        rows={4}
        size={'small'}
        sx={{ mt: 2 }}
        value={comment}
      />
    </ConfirmationDialog>
  );
};
