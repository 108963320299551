import { useQuery } from '@tanstack/react-query';
import {
  ALL_ITEMS_PRICING_SHEET_ID,
  MIRA_RAMITURVA_NAMES_QUERY_KEY,
} from '../../../shared/constants';
import { api } from '..';
import { ConvertedMiraSearchItem } from '../../../shared/types/mira';
import { produce } from 'immer';
import _ from 'lodash';
import { RamiturvaNames } from '../../../shared/types';

const useRamiturvaNames = () => {
  const itemType = 2;

  // fetch ramiturva names and insurance deductibles
  return useQuery({
    staleTime: Infinity,
    retry: false,
    queryKey: [
      MIRA_RAMITURVA_NAMES_QUERY_KEY,
      ALL_ITEMS_PRICING_SHEET_ID,
    ],
    queryFn: async () => {
      const result = await api.get<ConvertedMiraSearchItem[]>(
        `/items/mira/search?itemType=${itemType}&query=${encodeURIComponent('ramiturva')}`,
      );

      return result.data.reduce<RamiturvaNames>((acc, cur) => {
        const { catClass, name } = cur;
        if (catClass.startsWith('XR')) {
          // reduce array to {catclass: prices} object to make finding catclass more efficient
          return produce(acc, (draft: any) => {
            _.set(draft, `${catClass}.ramiturvaName`, name);
          });
        }
        return acc;
      }, {});
    },
  });
};

export default useRamiturvaNames;
