export enum ArchiveReason {
  ExpiredRenewedAsNewSheet = "Hinnasto vanhentui - uusitaan tekemällä uusi hinnasto",
  ExpiredNoRenew = "Hinnasto vanhentui - ei uusita, ei saavuta tavotteita",
  RedundantReplica = "Hinnasto tehty tuplana tai uusi versio",
  CustomerNoLead = "Hinnasto ei johtanut asiakkuuteen",
  CustomerRejectionPrice = "Asiakas hylkäsi hinnaston - Liian kallis",
  CustomerRejectionProduct = "Asiakas hylkäsi hinnaston - Vääränlainen kalusto",
  SalesManagerReject = "Myyntipäällikkö ei hyväksynyt",
  TestPricingSheet = "Testihinnasto",
  Other = "Muu syy, mikä?",
}

export type ArchiveReasonKeys = keyof typeof ArchiveReason;
