import {
  Alert,
  Box,
  Button,
  DialogContentText,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import {
  formatRentalPotential,
  formatRevenue,
} from '../../components/Steps/InitialInformationStep';
import { processEnvs } from '../../config/processEnvs';
import useUserContext from '../../providers/User/UserProvider';
import {
  ApprovalRequestState,
  PricingSheet,
  PricingSheetRevision,
  PricingSheetState,
  RoundingBasis,
} from '../../../../shared/types';
import {
  formatDateTime,
  formatDateTolocaleDateString,
} from '../../utils/formatDateTimes';
import MiraStatusDialog from './Dialogs/MiraStatusDialog';
import { currencyMultiplier } from '../../../../shared/constants';
import { formatPricingBasis } from '../../helpers/formatPricingBasis';
import {
  DetailLabelItem,
  RevisionDetailLabelItem,
} from '../OfferSheets/OfferSheetsTable/OffersTableRowDetails';
import {
  AccessTime,
  DeleteOutlined,
  SettingsBackupRestore,
  UnarchiveOutlined,
  Inventory2Outlined,
  SmsOutlined,
  Check,
} from '@mui/icons-material';
import { ConfirmationDialog } from '../../components/Common/ConfirmationDialog';
import {
  archivePricingSheetById,
  removePricingSheetById,
  restorePricingSheetById,
} from '../../services/pricingSheets';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import { Link } from 'react-router-dom';
import {
  getPricingSheetStateSeverity,
  getSheetStateIcon,
} from './helpers';
import getSheetState from '../../helpers/getSheetState';
import { RejectedOfferDialog } from '../OfferSheets/OfferSheetsTable/RejectedOfferDialog';
import {
  ArchiveReason,
  ArchiveReasonKeys,
} from '../../../../shared/types/pricingSheets';

export const formatRoundingBasis = (roundingBasis: RoundingBasis) => {
  if (roundingBasis === 'TWODECIMALS') {
    return 'Kaksi desimaalia';
  } else if (roundingBasis === 'SIXDECIMALS') {
    return 'Kuusi desimaalia';
  } else if (roundingBasis === 'THREESIGNIFICANT') {
    return 'Kolme merkitsevää';
  } else {
    return 'Tuntematon';
  }
};

const TableRow = ({ header, value }: any) => {
  return (
    <tr>
      <td>
        <Typography
          variant="subtitle2"
          component="div"
          gutterBottom
          color="primary"
        >
          {header}
        </Typography>
      </td>
      <td>
        <Typography
          sx={{ ml: 1 }}
          variant="body2"
          component="div"
          gutterBottom
          color="text.primary"
        >
          {value}
        </Typography>
      </td>
    </tr>
  );
};

const priceSheetXlsxUrl = (id: string) =>
  `${processEnvs.BACKEND_URI}/pricingsheets/xlsx/${id}`;

const priceSheetXlsxKitUrl = (id: string) =>
  `${processEnvs.BACKEND_URI}/pricingsheets/xlsx-kit/${id}`;

const AdditionalInfo = ({
  pricingSheet,
  updateSheets,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
}) => {
  const {
    revision,
    original,
    createdAt,
    archivedAt,
    id,
    name,
    state,
    approvalRequests,
    archiveReason,
    archiveReasonComment,
  } = pricingSheet;
  const [isOpenArchiveDialog, setIsOpenArchiveDialog] =
    React.useState(false);

  const [isOpenMiraStatusDialog, setIsMiraStatusDialog] =
    React.useState(false);

  const [isOpenRemoveDialog, setIsOpenRemoveDialog] =
    React.useState(false);

  const [isOpenRestoreDialog, setIsOpenRestoreDialog] =
    React.useState(false);

  const { userEmail, isAdmin } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);

  const handleCloseArchiveDialog = () => {
    setIsOpenArchiveDialog(false);
  };

  const handleOpenArchiveDialog = () => {
    setIsOpenArchiveDialog(true);
  };
  const handleCloseRemoveDialog = () => {
    setIsOpenRemoveDialog(false);
  };

  const handleOpenRemoveDialog = () => {
    setIsOpenRemoveDialog(true);
  };

  const handleCloseRestoreDialog = () => {
    setIsOpenRestoreDialog(false);
  };

  const handleOpenRestoreDialog = () => {
    setIsOpenRestoreDialog(true);
  };

  const handleCloseMiraStatusDialog = () => {
    setIsMiraStatusDialog(false);
  };

  const handleOpenMiraStatusDialog = () => {
    setIsMiraStatusDialog(true);
  };

  const onClickDownloadExportFile = () => {
    window.open(priceSheetXlsxUrl(pricingSheet.id));
  };

  const onClickDownloadKitsExportFile = () => {
    window.open(priceSheetXlsxKitUrl(pricingSheet.id));
  };

  const isArchiveEnabled = () => {
    return (
      (isAdmin || pricingSheet.userEmail === userEmail) &&
      (state === PricingSheetState.Draft ||
        state === PricingSheetState.WarningDraft ||
        state === PricingSheetState.OkDraft ||
        state === PricingSheetState.Ready ||
        state === PricingSheetState.Approved ||
        state === PricingSheetState.Pending ||
        (state === PricingSheetState.InUse && pricingSheet.expired))
    );
  };

  const isRemoveEnabled = () => {
    return (
      (isAdmin || pricingSheet.userEmail === userEmail) &&
      (state === PricingSheetState.Draft ||
        state === PricingSheetState.WarningDraft ||
        state === PricingSheetState.OkDraft)
    );
  };

  const formatExpiration = () => {
    const from = pricingSheet?.from
      ? formatDateTolocaleDateString(pricingSheet?.from)
      : null;
    const to = pricingSheet?.to
      ? formatDateTolocaleDateString(pricingSheet?.to)
      : null;
    return `${from} - ${to}`;
  };

  const isMiraStatusDisabled =
    !!pricingSheet.archivedAt ||
    (pricingSheet.state !== PricingSheetState.Ready &&
      pricingSheet.state !== PricingSheetState.InUse);

  const archiveSheet = async (
    archiveReason: ArchiveReasonKeys,
    comment: string,
  ) => {
    try {
      await archivePricingSheetById(id, archiveReason, comment);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} arkistoitu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} arkistoitaessa tapahtui virhe!`,
      });
    }
  };

  const removeSheet = async (id: string) => {
    try {
      await removePricingSheetById(id);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} poistettu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} poistaessa tapahtui virhe!`,
      });
    }
  };

  const restoreSheet = async (id: string) => {
    try {
      await restorePricingSheetById(id);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} palautettu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} palauttaessa tapahtui virhe!`,
      });
    }
  };

  const approver = approvalRequests?.find(
    (approvalRequest) =>
      approvalRequest.state === ApprovalRequestState.Approved,
  );

  type PricingRevisionDetailLabelItemProps = {
    label: string;
    item: PricingSheetRevision;
  };

  const PricingRevisionDetailLabelItem = ({
    item,
    label,
  }: PricingRevisionDetailLabelItemProps) => {
    const { id, state } = item;
    const severity = getPricingSheetStateSeverity(state);
    const stateLabel = getSheetState(state);
    const icon = getSheetStateIcon(state);

    const value = (
      <Link
        to={{
          pathname: `/tarkastele-hinnastoa/${id}`,
          state: { readOnly: true },
        }}
      >
        #{id}
      </Link>
    );

    return (
      <RevisionDetailLabelItem
        severity={severity}
        stateLabel={stateLabel}
        icon={icon}
        value={value}
        label={label}
      />
    );
  };

  return (
    <Grid
      sx={{ pb: 1, pt: 1 }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      {/** ARCHIVE PRICING SHEET DIALOG */}
      <RejectedOfferDialog
        open={isOpenArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onConfirm={archiveSheet}
        reasons={ArchiveReason}
        title={'Arkistoinnin syy'}
      />
      {/** REMOVE PRICING SHEET DIALOG */}
      <ConfirmationDialog
        open={isOpenRemoveDialog}
        confirmButtonLabel={'Poista'}
        confirmButtonProps={{
          color: 'error',
          startIcon: <DeleteOutlined />,
        }}
        onCancel={() => handleCloseRemoveDialog()}
        onConfirm={() => {
          removeSheet(id);
        }}
        title="Hinnaston poistaminen"
      >
        <Typography variant={'body1'} mb={2}>
          Olet poistamassa hinnaston <i>"{name}"</i>
          <br />
          Haluatko jatkaa?
        </Typography>
        <DialogContentText>
          Poistettua hinnastoa ei ole enää mahdollista palauttaa!
        </DialogContentText>
      </ConfirmationDialog>
      {/** RESTORE PRICING SHEET DIALOG */}
      <ConfirmationDialog
        open={isOpenRestoreDialog}
        confirmButtonLabel={'Palauta'}
        confirmButtonProps={{
          color: 'primary',
          startIcon: <SettingsBackupRestore />,
        }}
        onCancel={() => handleCloseRestoreDialog()}
        onConfirm={() => {
          restoreSheet(id);
        }}
        title="Hinnaston palauttaminen"
      >
        <Typography variant={'body1'} mb={2}>
          Olet palauttamassa hinnastoa <i>"{name}"</i>
          arkistoista
          <br />
          Haluatko jatkaa?
        </Typography>
        <DialogContentText>
          Poistettua hinnastoa ei ole enää mahdollista palauttaa!
        </DialogContentText>
      </ConfirmationDialog>
      {/** MIRA STATUS DIALOG */}
      <MiraStatusDialog
        isOpenMiraStatusDialog={isOpenMiraStatusDialog}
        handleCloseMiraStatusDialog={handleCloseMiraStatusDialog}
        pricingSheet={pricingSheet}
        updateSheets={updateSheets}
      />
      <table>
        <tbody>
          <TableRow
            header={'Hinnastonro'}
            value={pricingSheet?.id ?? '-'}
          />
          <TableRow
            header={'Asiakas'}
            value={pricingSheet?.customerName ?? '-'}
          />
          <TableRow
            header={'Asiakas Id'}
            value={pricingSheet?.customerId ?? '-'}
          />
          <TableRow
            header="Hinnaston taso"
            value={pricingSheet?.level ?? '-'}
          />
          <TableRow
            header="Hinnaston tyyppi"
            value={
              pricingSheet?.pricingBasis
                ? formatPricingBasis(pricingSheet.pricingBasis)
                : '-'
            }
          />
          <TableRow
            header="Työmaa"
            value={pricingSheet?.site ?? '-'}
          />
          <TableRow header="Voimassa" value={formatExpiration()} />
          <TableRow
            header="Pyöristys viennissä"
            value={
              pricingSheet?.roundingBasisSheet
                ? formatRoundingBasis(pricingSheet.roundingBasisSheet)
                : '-'
            }
          />
          <TableRow
            header="Pyöristys tulosteella"
            value={
              pricingSheet.roundingBasisPdf
                ? formatRoundingBasis(pricingSheet.roundingBasisPdf)
                : '-'
            }
          />
        </tbody>
      </table>
      <table style={{ marginLeft: 36 }}>
        <tbody>
          <TableRow
            header="Toimiala"
            value={pricingSheet?.industry ?? '-'}
          />
          <TableRow
            header="Liikevaihto"
            value={
              pricingSheet?.revenue
                ? formatRevenue(
                    (pricingSheet?.revenue as number) /
                      currencyMultiplier,
                  )
                : '-'
            }
          />
          <TableRow
            header="Vuokrapotentiaali"
            value={
              pricingSheet?.potentialBottom &&
              pricingSheet?.potentialTop
                ? formatRentalPotential(
                    (pricingSheet?.potentialBottom as number) /
                      currencyMultiplier,
                    (pricingSheet?.potentialTop as number) /
                      currencyMultiplier,
                  )
                : ''
            }
          />
          <TableRow
            header="Sitoutuminen"
            value={
              pricingSheet?.commitment
                ? `${pricingSheet?.commitment} %`
                : '-'
            }
          />
          <TableRow
            header="Alue"
            value={pricingSheet?.region ?? '-'}
          />
          <TableRow
            header="Toimii valtakunnallisesti"
            value={pricingSheet?.nationWide ? 'Kyllä' : 'Ei'}
          />
          <TableRow
            header="Alueellisesti merkittävä kohde"
            value={
              pricingSheet?.regionallySignificant ? 'Kyllä' : 'Ei'
            }
          />
          <TableRow
            header="Strategisesti merkittävä kohde"
            value={
              pricingSheet?.strategicallySignificant ? 'Kyllä' : 'Ei'
            }
          />
        </tbody>
      </table>
      <Grid alignItems="flex-start" columnSpacing={2}>
        <Alert style={{ minWidth: 300 }} severity="info" icon={false}>
          {createdAt && (
            <DetailLabelItem
              icon={AccessTime}
              label={'Hinnasto luotu:'}
              value={
                pricingSheet?.createdAt
                  ? formatDateTime(pricingSheet.createdAt)
                  : '-'
              }
            />
          )}
          {archivedAt && (
            <DetailLabelItem
              icon={AccessTime}
              label={'Hinnasto arkistoitu:'}
              value={
                pricingSheet?.archivedAt
                  ? formatDateTime(pricingSheet.archivedAt)
                  : '-'
              }
            />
          )}
          {approver && (
            <DetailLabelItem
              icon={Check}
              label={'Hyväksyjä:'}
              value={`${approver.approverUserEmail} (${formatDateTime(new Date(approver.updatedAt))})
               
            `}
            />
          )}
          {revision && (
            <PricingRevisionDetailLabelItem
              label={'Hinnastosta tehty uusi versio:'}
              item={revision}
            />
          )}
          {original && (
            <PricingRevisionDetailLabelItem
              label={'Hinnastosta aikaisempi versio:'}
              item={original}
            />
          )}
          {archiveReason && (
            <DetailLabelItem
              icon={Inventory2Outlined}
              label={'Arkistoinnin syy:'}
              value={ArchiveReason[archiveReason]}
            />
          )}
          {archiveReasonComment && (
            <DetailLabelItem
              icon={SmsOutlined}
              label={'Arkistoinnin kommentti:'}
              value={archiveReasonComment}
            />
          )}

          <br />
          <Grid
            sx={{ pb: 1, pt: 1 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
          >
            {/* REMOVE PRICING SHEET */}
            <Grid item xs={6}>
              <Button
                disabled={!isRemoveEnabled()}
                fullWidth
                size={'small'}
                variant={'contained'}
                startIcon={<DeleteOutlined />}
                color="error"
                onClick={handleOpenRemoveDialog}
              >
                Poista
              </Button>
            </Grid>
            {/* ARCHIVE PRICING SHEET  */}
            <Grid item xs={6}>
              {!archivedAt && (
                <Button
                  fullWidth
                  size={'small'}
                  variant={'outlined'}
                  startIcon={<UnarchiveOutlined />}
                  color="warning"
                  onClick={handleOpenArchiveDialog}
                >
                  Arkistoi
                </Button>
              )}
              {archivedAt && (
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={!isArchiveEnabled()}
                  startIcon={<SettingsBackupRestore />}
                  color="primary"
                  onClick={handleOpenRestoreDialog}
                >
                  Palauta hinnasto
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ mt: 2 }}>
            {`Hinnasto viety Miraan: ${
              pricingSheet?.state === PricingSheetState.InUse
                ? 'Kyllä'
                : 'Ei'
            }`}
            <br />
            {`Hinnaston MiraId: ${
              pricingSheet?.miraInfo?.miraId ?? '-'
            }`}
            <br />
            {`Hinnasto liitetty asiakkaisiin: ${
              pricingSheet?.miraInfo?.customerIds ?? '-'
            }`}
            {isAdmin && (
              <>
                <Box sx={{ mt: 1 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isMiraStatusDisabled}
                    color="primary"
                    onClick={handleOpenMiraStatusDialog}
                  >
                    Mira-status
                  </Button>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Button
                    size="small"
                    onClick={() => onClickDownloadExportFile()}
                  >
                    Lataa tuotelista
                  </Button>
                  <Button
                    size="small"
                    onClick={() => onClickDownloadKitsExportFile()}
                  >
                    Lataa nimikeluettelot
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default AdditionalInfo;
