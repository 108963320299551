import { AxiosError, AxiosResponse } from 'axios';
import { processEnvs } from '../config/processEnvs';
import {
  PricingSheet,
  PricingSheetPost,
  PricingSheetPut,
} from '../../../shared/types';
import { ExternalPricingSheet } from '../components/PricingTable/SearchExternalSheet';
import { api } from '../index';
import { ArchiveReasonKeys } from '../../../shared/types/pricingSheets';

// https://axios-http.com/docs/handling_errors
const handleAxiosError = (error: AxiosError) => {
  if (processEnvs.NODE_ENV !== 'production') {
    console.error('Handling error', error.toJSON());
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error data: ', error.response.data);
      console.error('Error status: ', error.response.status);
      console.error('Error headers: ', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('Error request: ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message: ', error.request);
    }
    console.error(error.config);
  }
  return {
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    message: (error.toJSON() as any).message,
    error: (error?.response?.data as any).error,
  };
};

export const handleAxiosRequest = async <T>(
  request: any,
): Promise<T> => {
  return request
    .then((response: AxiosResponse) => {
      return response.data as T;
    })
    .catch((error: AxiosError) => {
      throw handleAxiosError(error);
    });
};

export const isStatusOk = (statusCode: number) => {
  return statusCode >= 200 && statusCode < 300;
};

export const createPricingSheet = async (
  pricingSheet: PricingSheetPost,
): Promise<any> => {
  const createPricingSheetResponse = await api.post(
    '/pricingsheets',
    pricingSheet,
  );
  return createPricingSheetResponse.data;
};

export interface AdditionalInfo {
  oldSheetId?: string; // mira id
  projectId?: string;
  comment?: string;
  clientIds?: string[]; // mira client ids
}

export const updatePricingSheetById = async (
  id: string,
  pricingSheet: PricingSheetPut,
  additionalInfo?: AdditionalInfo,
) => {
  const updatePricingSheetResponse = await api.put(
    `/pricingsheets/${id}`,
    {
      pricingSheet: pricingSheet,
      additionalInfo: additionalInfo,
    },
  );

  return updatePricingSheetResponse.data;
};

export const getPricingSheets = (isArchived: boolean = false) => {
  const getPricingSheetsResponse = api
    .get(`/pricingsheets?isArchived=${isArchived}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return getPricingSheetsResponse;
};

export const getPricingSheetById = async (id: string) => {
  return handleAxiosRequest<PricingSheet>(
    api.get(`/pricingsheets/${id}`, {
      validateStatus: (status) => isStatusOk(status),
    }),
  );
};

export const archivePricingSheetById = (
  id: string,
  archiveReason: ArchiveReasonKeys,
  comment: string,
) => {
  return api.post('/pricingsheets/archive', {
    id,
    archiveReason,
    comment,
  });
};

export const restorePricingSheetById = (id: string) => {
  return api.post('/pricingsheets/restore', { id });
};

export const removePricingSheetById = (id: string) => {
  return api.post('/pricingsheets/remove', { id });
};

export const createNewVersionById = async (id: string) => {
  const createNewVersionByIdResponse = await api.post(
    '/pricingsheets/new-version',
    { id },
  );
  return createNewVersionByIdResponse.data;
};

export const getPricingSheetMira = async (id: string) => {
  const getItemsResponse = await api.get<ExternalPricingSheet>(
    `/pricingsheets/mira/${id}`,
  );
  return getItemsResponse.data;
};

export const editLock = async (id: string) => {
  const getItemsResponse = await api.put<ExternalPricingSheet>(
    `/pricingsheets/edit-lock/${id}`,
    {},
  );
  return getItemsResponse.data;
};
